import React from 'react';
import {Spring, config, animated} from 'react-spring/renderprops';
import {css} from 'emotion';


/*global tw*/
const styles = {
    blackOverlay: css`
        ${tw`fixed z-200 h-screen w-screen overflow-hidden pin-t`};
        background: rgba(0,0,0,0.77);
    `,
}

const BlackOverlay = (props) => (

    <Spring native 
            from={{ display: 'none' }} 
            to={{ display: props.active ? 'flex' : 'none' }}>
        {values =>
            <animated.div onClick={props.onClick} className={styles.blackOverlay} style={values}>
                {props.children}
            </animated.div>
        }
    </Spring>

);

export default BlackOverlay